import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';
import AdButler from '../../components/ThankyouPages/AdButler';
import adbutlerZones from '../../utils/adbutlerZones';
import storage from '../../utils/storage';

const RateContainer = styled.main`
  font-family: 'Nunito Sans', sans-serif;

  h1 {
    color: #ae1a1a;
    font-weight: normal;
    font-size: 2.1em;
    text-align: center;
  }
  p {
    text-align: center;
  }
`;

// fake vwo functions to allow vwo to fail on this silently w/o blowing up Sentry
try {
  window.alterConfig = () => {
    // fail silently
  };
  window.alterQuestions = () => {
    // fail silently
  };
  window.addQuestion = () => {
    // fail silently
  };
  window.removeQuestion = () => {
    // fail silently
  };
  window.changeFromSlider = () => {
    // fail silently
  };
} catch (error) {
  // fail silently
}
const Lb = () => {
  const [adIds, setAdIds] = useState([]);
  let parsedGlobalConfig = {};
  const globalConfig = storage('session', 'get', 'globalConfig');
  if (globalConfig) {
    parsedGlobalConfig = JSON.parse(globalConfig);
  }

  useEffect(() => {
    const scData = storage('session', 'get', 'scData');
    const adObject = adbutlerZones('lb', parsedGlobalConfig.isSCLabs);

    if (scData) {
      const parsedScData = JSON.parse(scData);
      const { siteName } = parsedScData[0];
      if (siteName) {
        if (adObject[siteName]) {
          setAdIds(adObject[siteName]);
        } else {
          setAdIds(adObject.default);
        }
      } else {
        setAdIds(adObject.default);
      }
    } else {
      setAdIds(adObject.default);
    }
  }, [parsedGlobalConfig.isSCLabs]);

  return (
    <RateContainer>
      <AdButler
        adButlerIds={adIds}
        adButlerAcctId={parsedGlobalConfig.isSCLabs ? '180988' : '169214'}
      />
    </RateContainer>
  );
};

export default withThankyouWrapper(Lb);
